import React from 'react'
import 'react-toggle/style.css'

/* eslint-disable */
const LightbulbModal = ({category, hint, setShowAckHint, showAckHint}) => {
    if (category == 'name'){
      return (
        <div className="flex text-2xl items-center justify-between align-center bg-zinc-100 rounded-md p-3 lg:p-8">
              <h1>Tip - Today&apos;s Blerdle is a famous {category}. It may be the first name, last name or nickname of an athlete, entertainer, historical or political figure.</h1>
        </div>
      )
      }
      else if (category == 'acronym'){
        const classN = hint === '' ? 'invisible' : ''
        return(
          <div>
            <h1>Tip - Today&apos;s Blerdle is an {category} of a common saying.</h1>
            <br/>
            <h1 className="underline cursor-pointer" onClick={()=>setShowAckHint(!showAckHint)}>Need a hint?</h1>
            <h1 className={classN}>The saying contains the word {hint}.</h1>
          </div>
          )
      } else{
      return(
        <div>
          < h1>Tip - Today&apos;s Blerdle is a {category}</h1>
        </div>
        )
    }

  }


export default LightbulbModal
