import React from 'react'
import PropTypes from 'prop-types'

const Block = (props) => {
  const { content, cols, color } = props
  if (cols === 6) {
    return (
      <div className={`${color} h-sm-6 lg:h-lg-6 md:h-md-6 lg:text-sm p-1 rounded-md flex align-middle justify-center align-center`}>
        <p className="font-bold text-1xl md:text-4xl lg:text-5xl align-middle">{content}</p>
      </div>
    )
  } if (cols === 5) {
    return (
      <div className={`${color} h-sm-5 lg:h-lg-5 md:h-md-5 lg:text-sm p-1 rounded-md flex align-middle justify-center align-center`}>
        <p className="font-bold text-2xl md:text-5xl lg:text-6xl align-middle">{content}</p>
      </div>
    )
  }
  return (
    <div className={`${color}  h-sm-4 lg:h-lg-4 md:h-md-4 lg:text-sm p-1 rounded-md flex align-middle justify-center align-center`}>
      <p className="font-bold text-4xl md:text-7xl lg:text-7xl lg:pt-2 align-middle">{content}</p>
    </div>
  )
}

Block.propTypes = {
  content: PropTypes.string.isRequired,
  cols: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

export default Block
