import React, { useEffect } from 'react'
import firebase from 'firebase/app'
import Blerdle from './components/Blerdle'
import Layout from './components/Layout'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

function App() {
  useEffect(() => {
    firebase.initializeApp({
      apiKey: 'AIzaSyB-2HEKBDR_pIe8MCGTWGfkZZPoqXweEPg',
      authDomain: 'blerdle.firebaseapp.com',
      projectId: 'blerdle',
      storageBucket: 'blerdle.appspot.com',
      messagingSenderId: '1025461976004',
      appId: '1:1025461976004:web:44b4c3fd2663730653c291',
      measurementId: 'G-CXGH1Y9S8G',
    })
    const analytics = firebase.analytics()
    analytics.logEvent('user_engagement')
  }, [])
  return (
    <>
      <Layout>
        <Blerdle />
      </Layout>
    </>
  )
}

export default App
