import React from 'react'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'

/* eslint-disable */
const SettingsModal = ({setTheme, isDarkTheme}) => {
  return (
    <div className="flex items-center justify-between align-center bg-zinc-100 rounded-md p-3 lg:p-8">
      <h1 className="text-correct-green font-bold">Code Switch</h1>
      <div className='mt-2'>
            <Toggle
              defaultChecked={!isDarkTheme}
              className='custom align-middle' 
              onChange={()=>(setTheme(!isDarkTheme))} />
      </div>
    </div>
  )
}

export default SettingsModal
