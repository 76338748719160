/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from 'react-share'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import share from '../img/Group 13934.png'

const SuccessModal = (props) => {
  const { word, meaning, state, isDark, position, showCopied, showCopiedFunc, index, fail} = props
  const visible = showCopied ? '' : 'hidden'
  const lightBlocks = {'bg-correct-green':'🟩', 
  'bg-wrong-gray': '⬜', 
  'bg-yellow':'🟨'}
  const darkBlocks = {'bg-correct-green':'🟩', 
  'bg-wrong-gray': '⬛', 
  'bg-yellow':'🟨'}
  const blockArray = isDark ? state.slice(0,position).map((element) => darkBlocks[element.color])  : state.slice(0,position).map((element) => lightBlocks[element.color]) 
  let text = `Blerdle ${index+1}\n`
  for (let i = 0; i < blockArray.length; i++){
    text = `${text}${blockArray[i]}`
    if(i % word.length === word.length -1)
    {
      text = `${text}\n`
    }
  }
  
  return (
    <div className="flex flex-col items-center justify-center align-center bg-zinc-100 rounded-md">
      <div className="mt-2 lg:mt-7 pt-2 font-bold sm:text-2xl md:text:3xl lg:text-4xl tracking-wide uppercase">
        <h1>{!fail?'You Did That!':'You Tried It!'}</h1>
      </div>
      <div className="mt-2 lg:mt-5 pt-2 font-bold tracking-wide text-center">
        <h1 className="sm:text-2xl md:text:3xl lg:text-4xl uppercase">Today&apos;s Blerdle:</h1>
        <h2 className="sm:text-1xl md:text:2xl lg:text-3xl uppercase">{word}</h2>
        <h2 className="sm:text-xl md:text:1xl lg:text-2xl">{meaning}</h2>
      </div>
      {word.length ===4 && (<div className={`mt-2 lg:mt-5 lg:w-1/5 md:w-1/4 w-1/3 grid grid-cols-${word.length} gap-1`}>
        { state.map((element, index) => <div className={`lg:h-slg-4 md:h-smd-4 border-gray-500 border-solid border h-ssm-4 flex align-middle justify-center align-center ${element.color}`} key={index.toString()} />) }
      </div>)}
      {word.length ===5 && (<div className={`mt-2 lg:mt-5 lg:w-1/5 md:w-1/4 w-1/3 grid grid-cols-${word.length} gap-1`}>
        { state.map((element, index) => <div className={`lg:h-slg-5 md:h-smd-5 border-gray-500 border-solid border h-ssm-5 flex align-middle justify-center align-center ${element.color}`} key={index.toString()} />) }
      </div>)}
      {word.length ===6 && (<div className={`mt-2 lg:mt-5 lg:w-1/5 md:w-1/4 w-1/3 grid grid-cols-${word.length} gap-1`}>
        { state.map((element, index) => <div className={`lg:h-slg-6 md:h-smd-6 border-gray-500 border-solid border h-ssm-6 flex align-middle justify-center align-center ${element.color}`} key={index.toString()} />) }
      </div>)}
      <div className="mt-3 flex flex-row justify-space-around">
        <div className="mx-1">
        <CopyToClipboard text={`${text}\nhttps://blerdle.com/`}
          onCopy={() => showCopiedFunc(true)}>
          <img src={share} style={{ width: '32px', height: '32px' }} className="cursor-pointer" alt="share"/>
          </CopyToClipboard>
        </div>
        <div className="mx-1">
          <TwitterShareButton url={'https://blerdle.com/'} title={text}><TwitterIcon size={32} round/></TwitterShareButton>
        </div>
      </div>
      <p className={visible}>Copied to Clipboard</p>
      <div className="mt-2 lg:mb-5 lg:mt-5 pt-2 font-bold sm:text-2xl md:text:3xl lg:text-4xl tracking-wide uppercase text-center">
        <h1>Come back tomorrow for</h1>
        <h2>a new challenge!</h2>
      </div>
    </div>
  )
}

SuccessModal.propTypes = {
  word: PropTypes.string.isRequired,
  meaning: PropTypes.string.isRequired,
  state: PropTypes.arrayOf(PropTypes.shape({
    word: PropTypes.string,
    color: PropTypes.string,
  })).isRequired,
}

export default SuccessModal
