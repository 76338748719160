/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import ReactModal from 'react-modal'
import Block from './Block'
import FailModal from './FailModal'
import SuccessModal from './SuccessModal'
import HelpModal from './Help'
import SettingsModal from './settingsModal'
import LightbulbModal from './LightbulbModal'
import { AllBlerdles, valid4Word, valid5Word, valid6Word, validName, validAcronym } from './Words'

function Blerdle() {
  const BLERDLESTART = new Date(2023, 6, 26)
  const WORDS = AllBlerdles
  const MILLI = 1000 * 60 * 60 * 24
  const WIN = 1
  const LOSE = 0
  const INPROGRESS = -1
  const CURRDATE = new Date()
  const [TODAY] = useState(new Date(CURRDATE.getFullYear(), CURRDATE.getMonth(), CURRDATE.getDate()))
  const [CURRIND] = useState(Math.ceil((TODAY - BLERDLESTART) / MILLI)%139)
  const lastPlayed = localStorage.getItem('LastPlayed') === null ? TODAY : new Date(localStorage.getItem('LastPlayed'))
  const [showHelpModal, setShowHelpModal] = useState(localStorage.getItem('firstTime') === null)
  const [update, setUpdate] = useState(true)
  if (TODAY - lastPlayed !== 0)
  {
    const them = localStorage.getItem('isDarkTheme')
    localStorage.clear()
    localStorage.setItem('isDarkTheme', them)
    localStorage.setItem('firstTime','false')
  }
  
  const [word] = useState((WORDS[CURRIND].split(',')[1]).toUpperCase ())
  const [category] = useState((WORDS[CURRIND].split(',')[0]))
  const [meaning] = useState(WORDS[CURRIND].split(',').length > 2? WORDS[CURRIND].split(',')[2] : '')
  const [state, setState] = useState(localStorage.getItem('State') !== null && lastPlayed.getTime() === TODAY.getTime() ? JSON.parse(localStorage.getItem('State')) : new Array(word.length * (word.length + 1)).fill({word:'', color:'bg-neutral-white'}))
  const [position, setPosition] = useState(localStorage.getItem('Position') === null ||  lastPlayed.getTime() !== TODAY.getTime() ? 0 : parseInt(localStorage.getItem('Position'), 10))
  const [greenKeys] = useState(localStorage.getItem('Green') !== null && lastPlayed.getTime() === TODAY.getTime() ? JSON.parse(localStorage.getItem('Green')) : ['@'])
  const [yellowKeys] = useState(localStorage.getItem('Yellow') !== null && lastPlayed.getTime() === TODAY.getTime() ? JSON.parse(localStorage.getItem('Yellow')) : ['@'])
  const [greyKeys] = useState(localStorage.getItem('Grey') !== null && lastPlayed.getTime() === TODAY.getTime() ? JSON.parse(localStorage.getItem('Grey')) : ['@'])
  const [gameOver, setGameOver] = useState(localStorage.getItem('GameOver') === null ? INPROGRESS : parseInt(localStorage.getItem('GameOver'), 10))
  const [isDarkTheme, setIsDarkTheme] = useState(localStorage.getItem('isDarkTheme') === null || localStorage.getItem('isDarkTheme') === 'true')
  const [showGameOverModal, setShowGameOverModal] = useState(gameOver === WIN)
  const [showFailModal, setShowFailModal] = useState(gameOver === LOSE)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [bounce, setBounce] = useState('')
  const [showCopied, setShowCopied] = useState(false)
  const [showLightbulbModal, setShowLightbulbModal] = useState(false)
  const [showAckHint, setShowAckHint] = useState(false)
  const [firstTimeHint, setFirstTimeHint]=useState(localStorage.getItem('FirstTimeHint') === null ? true : false)

  const checkValid = (input) => {
    if(category === 'word')
    {
      if(word.length===4){
        return valid4Word.indexOf(input) !== -1
      }
      else if(word.length===5){
        return valid5Word.indexOf(input) !== -1
      }
      else if(word.length===6){
        return valid6Word.indexOf(input) !== -1
      }
    }
    if(category === 'name')
    {
      if(word.length===4){
        return valid4Word.indexOf(input) !== -1 || validName.indexOf(input) !== -1
      }
      else if(word.length===5){
        return valid5Word.indexOf(input) !== -1 || validName.indexOf(input) !== -1
      }
      else if(word.length===6){
        return valid6Word.indexOf(input) !== -1 || validName.indexOf(input) !== -1
      }
    }
    if(category === 'ack')
    {
      return true; //for now if it's an acronym, let them guess any word cos acronyms are hard
    }
  }


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      maxWidth:'82vw',
      transform: 'translate(-50%, -50%)',
      borderRadius: '1.5rem',
      backgroundColor:'#f4f4f5'
    },
  };

  const anotherCustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width:'50vw',
      height:'50vw',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '1.5rem',
      backgroundColor:'#f4f4f5'
    },
  };

  const lightBulbClick = () => {
    if(firstTimeHint)
    {
      setFirstTimeHint(false)
      localStorage.setItem('FirstTimeHint','false')
    }
    setShowLightbulbModal(true)
  }
  const onKeyPress = (button) => {
    const newState = state
    if (gameOver === INPROGRESS) {
      if (button == '{bksp}' && position % word.length !== 0) {
        if ( position % word.length === word.length -1 && newState[position].word!=='') {
          newState[position] = {word:'', color:'bg-neutral-white'}
          setState(newState)
          setUpdate(!update)
        } else {
          newState[position - 1] = {word:'', color:'bg-neutral-white'}
          setState(newState)
          setPosition(position - 1)
        }
      } else if (button != '{enter}' && button != '{bksp}' && newState[position].word==='' ) {
        newState[position] = {word:button, color:'bg-neutral-white'}
        setState(newState)
        if ( position % word.length === word.length -1) {
          setUpdate(!update)
        } else {
          setPosition(position + 1)
        }
      } else if (button == '{enter}' && position % word.length === word.length -1 && newState[position].word !=='' ) {
        let allGreen = true
        const startInd = position - (word.length - 1)
        // is it valid word
        const input = newState.slice(startInd, position + 1).map(x=>x.word).join('')
        if (checkValid(input)){
          for (let i = 0; i < word.length; i++){
            if (newState[startInd + i].word === word.charAt(i)){
              newState[startInd + i].color = 'bg-correct-green'
              if (greenKeys.indexOf(newState[startInd + i].word) === -1){
                greenKeys.push(newState[startInd + i].word)
                if(yellowKeys.indexOf(newState[startInd + i].word) !== -1){
                  yellowKeys.filter(x=> x == newState[startInd + i].word)
                }
              }
            } else {
              allGreen = false
              const numLetters = word.split(newState[startInd + i].word).length - 1
              if (numLetters === 0) {
                newState[startInd + i].color = 'bg-wrong-gray'
                if (greyKeys.indexOf(newState[startInd + i].word)  === -1){
                  greyKeys.push(newState[startInd + i].word)
                }
              } else {
                let prevIncorrect = 0
                let correct = 0
                const currentLetter = newState[startInd + i].word
                for (let j = 0; j < word.length; j++) {
                  if (newState[startInd + j].word === currentLetter){
                    if (newState[startInd + j].word === word.charAt(j)){
                      correct += 1
                    } else if (j < i) {
                      prevIncorrect += 1
                    }
                  }
                }
                if ((numLetters - prevIncorrect) - correct > 0) {
                  newState[startInd + i].color = 'bg-yellow'
                  if (yellowKeys.indexOf(newState[startInd + i].word)  === -1 && greenKeys.indexOf(newState[startInd + i].word) === -1){
                    yellowKeys.push(newState[startInd + i].word)
                  }
                } else {
                  newState[startInd + i].color = 'bg-wrong-gray'
                }
              }
            }
          }
          setPosition(position + 1)
          setState(newState)
          if (allGreen) {
            setGameOver(WIN)
          } else if (position === state.length -1){
            setGameOver(LOSE)
          }
        }
        else {
          setBounce('animate-bounce ')
        }      
        //set local storage
        localStorage.setItem('Grey', JSON.stringify(greyKeys))
        localStorage.setItem('Green', JSON.stringify(greenKeys))
        localStorage.setItem('Yellow', JSON.stringify(yellowKeys))
        
      }
      localStorage.setItem('State', JSON.stringify(state))
      localStorage.setItem('LastPlayed', TODAY)
    }
  }
  useEffect(() => {
    localStorage.setItem('firstTime','false')
  }, [])

  useEffect(() => {
    localStorage.setItem('Position', position)
  }, [position])

  useEffect(() => {
    localStorage.setItem('ShowAckHint', showAckHint)
  }, [showAckHint])

  useEffect(() => {
    if (bounce != ''){
      setTimeout(() => setBounce(''), 2000)
    }
  }, [bounce])

  useEffect(() => {
    if (showCopied){
      setTimeout(() => setShowCopied(false), 2000)
    }
  }, [showCopied])
  
  useEffect(() => {
    if (gameOver === WIN || gameOver === LOSE) {
      setTimeout(() => setShowGameOverModal(true), 800)
    }
    localStorage.setItem('GameOver', gameOver)
  }, [gameOver])
  
  useEffect(() => {
    localStorage.setItem('isDarkTheme', isDarkTheme)
    var element = document.body;
    if(isDarkTheme) {
      element.classList.remove("light-mode")
      element.classList.add("dark-mode")
    } else {
      element.classList.add("light-mode")
      element.classList.remove("dark-mode")
    }
  }, [isDarkTheme])
  return (
    <>
    <div className="flex flex-col items-center justify-between h-small-screen">
       <div className="lg:w-1/3 md:w-1/2 w-4/5 mt-10 pt-5 pl-1 justify-self-start font-bold text-3xl lg:text-5xl flex justify-between tracking-wide uppercase">
          <h1 className="invisible">hi</h1>
          <div className="flex flex-col">
            <h1>blerdle</h1>
            <p className={`text-center ${firstTimeHint ? 'animate-bounce': ''} text-correct-green text-xs lg:text-sm underline cursor-pointer`} onClick={()=>lightBulbClick()}>Today's category</p>
          </div>
          {isDarkTheme && (<div className="ml-2 lg:ml-10 md:ml-9 justify-self-end">
            <span className="mr-2 md-light material-icons cursor-pointer" onClick={()=>(setShowHelpModal(true))}>help</span>
            <span className="md-light material-icons cursor-pointer" onClick={()=>(setShowSettingsModal(true))}>settings</span>
          </div>)}
          {!isDarkTheme && (<div className="ml-2 lg:ml-10 md:ml-9 justify-self-end">
            <span className="mr-2 material-icons cursor-pointer" onClick={()=>(setShowHelpModal(true))}>help</span>
            <span className="material-icons cursor-pointer" onClick={()=>(setShowSettingsModal(true))}>settings</span>
          </div>)}
        </div>
        <ReactModal
          isOpen={showHelpModal}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={()=>setShowHelpModal(false)}
          ariaHideApp={false}
        >
          <HelpModal n={word.length+1}/>
        </ReactModal>
        <ReactModal
          isOpen={showLightbulbModal}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={()=>setShowLightbulbModal(false)}
          ariaHideApp={false}
        >
          <LightbulbModal category={category ==='ack'? 'acronym' : category} hint={showAckHint && category ==='ack' ? WORDS[CURRIND].split(',')[3] :''} setShowAckHint={setShowAckHint} showAckHint={showAckHint}/>
        </ReactModal>
        <ReactModal
          isOpen={showSettingsModal}
          style={anotherCustomStyles}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={()=>setShowSettingsModal(false)}
          ariaHideApp={false}
        >
          <SettingsModal setTheme={setIsDarkTheme} isDarkTheme={isDarkTheme}/>
          <hr
            style={{
                color: '#009000',
                borderColor: '#009000',
                height: 5,    
            }}
    />
        </ReactModal>
        <ReactModal
          isOpen={showGameOverModal}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={()=>setShowGameOverModal(false)}
          ariaHideApp={false}
        >
          <SuccessModal word={word} meaning={meaning} state={state} isDark={isDarkTheme} position={position} showCopied={showCopied} showCopiedFunc={setShowCopied} index={CURRIND} fail={gameOver === LOSE}/>
        </ReactModal>
        { word.length === 6 && (
          <>
            <div className={`${bounce}grid lg:w-1/3 md:w-1/2 w-4/5 grid-cols-6 mt-4 gap-x-1 gap-y-1.5 p-2`}>
              { state.map((element, index) => <Block key={index.toString()} content={element.word} cols={6}  color={element.color} />) }
            </div>
          </>
        )}
        { word.length === 5 && (
          <>
            <div className={`${bounce}grid lg:w-1/3 md:w-1/2 w-4/5 grid-cols-5 mt-4 gap-x-1 gap-y-1.5 p-2`}>
              { state.map((element, index) => <Block key={index.toString()} content={element.word} cols={5} color={element.color} />) }
            </div>
          </>
        )}
        { word.length === 4 && (
          <>
            <div className={`${bounce}grid lg:w-1/3 md:w-1/2 w-4/5 grid-cols-4 mt-4 gap-1 p-2`}>
              { state.map((element, index) => <Block key={index.toString()} content={element.word} cols={4} color={element.color} />) }
            </div>
          </>
        )}
        { bounce !== '' && (
          <>
            <div className="text-white text-bold">
              <p>Not in list for this game</p>
            </div>
          </>
        )}
        <div className="transparent justify-self-end w-full md:w-3/5 lg:w-1/2 pb-8">
          <Keyboard
            onKeyPress={onKeyPress}
            theme="hg-theme-default"
            buttonTheme={[
              {
                class: "grayButtons",
                buttons: greyKeys.join(' ')              
              },
              {
                class: "yellowButtons",
                buttons: yellowKeys.join(' ')
              },
              {
                class: "greenButtons",
                buttons: greenKeys.join(' ')
              }
            ]}
            display={
              {
                '{enter}': 'Enter',
                '{bksp}': 'Del',
              }
            }
            layout={
              {
                default: [
                  '` 1 2 3 4 5 6 7 8 9 0 - = {shift}',
                  '{tab} Q W E R T Y U I O P [ ] \\',
                  '{lock} A S D F G H J K L ; \' {shift}',
                  '{enter} Z X C V B N M , . / {bksp}',
                  '.com @ {space}',
                ],
              }
            }
            excludeFromLayout={
              {
                default: ['@', '.com', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '{shift}', '{tab}', '{lock}', ';', ',', '[', ']', '.', '\\', '/', '\'', '{space}', '`', '=', '-'],
                shift: ['@', '.com'],
              }
            }
          />
        </div>
      </div>
    </>
  )
}

export default Blerdle
