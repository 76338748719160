import React from 'react'

/* eslint-disable */
const HelpModal = ({n}) => {
  return (
    <div className="flex flex-col bg-zinc-100 rounded-md">
      <div className="text-correct-green mt-3 px-5 lg:mt-7 pt-2 text-xs lg:text-base tracking-wide">
        <h1 className='font-bold'>Blerdle is inspired by Wordle and created in celebration of Black History Month, so Blerdles are themed around Black culture and can be a word or name.</h1>
        <h1 className='font-bold'>So think Black!</h1>
        <hr className='font-bold'
            style={{
                color: '#009000',
                borderColor: '#009000',
                height: 5,    
                marginTop: '0.25rem',
                marginBottom: '0.25rem'
            }}
            />
        <h1>Guess the BLERDLE in {n} tries.</h1>
        <h1>Each guess must be a valid {n-1} letter word. Hit the enter button to submit.</h1>
        <h1>After each guess, the color of the tiles will change to show how close your guess was to the word.</h1>
        <br/>
        <h1>A new BLERDLE will be available each day!</h1>
      </div>
      <div className="mt-1 px-5 lg:mt-5 pt-2 tracking-wide">
        <h1 className="text-correct-green  sm:text-lg md:text:xl lg:text-2xl font-bold uppercase">Examples</h1>
        <div className="grid lg:w-1/4 md:w-1/3 w-3/5 grid-cols-4 mt-2 gap-1 p-1">
            <div className={`bg-neutral-white  h-hsm-4 lg:h-hlg-4 md:h-lg-4 lg:text-xs p-1 border-gray-500 border-solid border-2 rounded-md flex align-middle justify-center align-center`}>
                <p className="font-bold text-3xl md:text-4xl lg:text-5xl lg:pt-2 align-middle">P</p>
            </div>
            <div className={`bg-neutral-white  h-hsm-4 lg:h-hlg-4 md:h-lg-4 lg:text-xs p-1 border-gray-500 border-solid border-2 rounded-md flex align-middle justify-center align-center`}>
                <p className="font-bold text-3xl md:text-4xl lg:text-5xl lg:pt-2 align-middle">R</p>
            </div>
            <div className={`bg-correct-green   h-hsm-4 lg:h-hlg-4 md:h-lg-4 lg:text-xs p-1 border-gray-500 border-solid border-2 rounded-md flex align-middle justify-center align-center`}>
                <p className="font-bold text-3xl md:text-4xl lg:text-5xl lg:pt-2 align-middle">A</p>
            </div>
            <div className={`bg-neutral-white   h-hsm-4 lg:h-hlg-4 md:h-lg-4 lg:text-xs p-1 border-gray-500 border-solid border-2 rounded-md flex align-middle justify-center align-center`}>
                <p className="font-bold text-3xl md:text-4xl lg:text-5xl lg:pt-2 align-middle">Y</p>
            </div>
        </div>
        <p className='p-2 text-xs lg:text-base text-correct-green'>The letter A is in the word and in the correct spot.</p>
        <div className="grid lg:w-1/4 md:w-1/3 w-3/5 grid-cols-4 mt-1 gap-1 p-1">
            <div className={`bg-yellow  h-hsm-4 lg:h-hlg-4 md:h-lg-4 lg:text-xs p-1 border-gray-500 border-solid border-2 rounded-md flex align-middle justify-center align-center`}>
                <p className="font-bold text-3xl md:text-4xl lg:text-5xl lg:pt-2 align-middle">G</p>
            </div>
            <div className={`bg-neutral-white  h-hsm-4 lg:h-hlg-4 md:h-lg-4 lg:text-xs p-1 border-gray-500 border-solid border-2 rounded-md flex align-middle justify-center align-center`}>
                <p className="font-bold text-3xl md:text-4xl lg:text-5xl lg:pt-2 align-middle">U</p>
            </div>
            <div className={`bg-neutral-white   h-hsm-4 lg:h-hlg-4 md:h-lg-4 lg:text-xs p-1 border-gray-500 border-solid border-2 rounded-md flex align-middle justify-center align-center`}>
                <p className="font-bold text-3xl md:text-4xl lg:text-5xl lg:pt-2 align-middle">A</p>
            </div>
            <div className={`bg-neutral-white   h-hsm-4 lg:h-hlg-4 md:h-lg-4 lg:text-xs p-1 border-gray-500 border-solid border-2 rounded-md flex align-middle justify-center align-center`}>
                <p className="font-bold text-3xl md:text-4xl lg:text-5xl lg:pt-2 align-middle">P</p>
            </div>
        </div>
        <p className='p-2 text-xs lg:text-base text-correct-green'>The letter G is in the word but in the wrong spot.</p>
        <div className="grid lg:w-1/4 md:w-1/3 w-3/5 grid-cols-4 mt-1 gap-1 p-1">
            <div className={`bg-neutral-white  h-hsm-4 lg:h-hlg-4 md:h-lg-4 lg:text-xs p-1 border-gray-500 border-solid border-2 rounded-md flex align-middle justify-center align-center`}>
                <p className="font-bold text-3xl md:text-4xl lg:text-5xl lg:pt-2 align-middle">S</p>
            </div>
            <div className={`bg-neutral-white   h-hsm-4 lg:h-hlg-4 md:h-lg-4 lg:text-xs p-1 border-gray-500 border-solid border-2 rounded-md flex align-middle justify-center align-center`}>
                <p className="font-bold text-3xl md:text-4xl lg:text-5xl lg:pt-2 align-middle">T</p>
            </div>
            <div className={`bg-neutral-white   h-hsm-4 lg:h-hlg-4 md:h-lg-4 lg:text-xs p-1 border-gray-500 border-solid border-2 rounded-md flex align-middle justify-center align-center`}>
                <p className="font-bold text-3xl md:text-4xl lg:text-5xl lg:pt-2 align-middle">A</p>
            </div>
            <div className={`bg-wrong-gray  h-hsm-4 lg:h-hlg-4 md:h-lg-4 lg:text-xs p-1 border-gray-500 border-solid border-2 rounded-md flex align-middle justify-center align-center`}>
                <p className="font-bold text-3xl md:text-4xl lg:text-5xl lg:pt-2 align-middle">N</p>
            </div>
        </div>
        <p className='p-2 text-xs lg:text-base text-correct-green'>The letter N is not in the word.</p>
      </div>
    </div>
  )
}

export default HelpModal
