import PropTypes from 'prop-types'
import React from 'react'

const Layout = ({ children }) => (
  <div className="h-screen overflow-x-hidden">
    <main className="mx-auto h-full">{children}</main>
  </div>
)

Layout.propTypes = {
  children: PropTypes.PropTypes.shape({}).isRequired,
}

export default Layout
